import { showDetailDialog, showDetailDialogPricing, toastError } from "../redux/slice/other.slice"
import { industries, services } from "../constant/frontend/home"
import { useDispatch, useSelector } from "react-redux"
import { setStatus } from "../redux/slice/mail.slice"
import MuiIcons from "../assets/js/MuiIcon"
import { Fragment, useState } from "react"
import Images from "../assets/images"
import {
  Box, Button, Collapse, Container,
  Grid2 as Grid, Input, styled, Typography
} from "@mui/material"

const Paragraph = styled("p")(() => ({}))
const Image = styled("img")(() => ({}))
function Pricing() {
  const dispatch = useDispatch()
  const [calculation, setCalculation] = useState({})
  const varified = sessionStorage.getItem("varified")
  const [industry, setIndustry] = useState(industries[0])
  const mailData = useSelector(state => state.mailSlice)

  const handleTransactionSubmit = (e) => {
    e.preventDefault()
    const form = new FormData(e.target)
    const formData = Object.fromEntries(form)
    const transactionValue = parseInt(formData?.transactions)
    if (!transactionValue || transactionValue < 0) {
      dispatch(toastError("Transaction value is required!"))
      return
    }

    const priceRange = handleCalculation(transactionValue)
    if (varified === "true") {
      dispatch(setStatus())
      return
    }
    dispatch(showDetailDialog({ industry: industry?.name, priceRange }))
  }
  const handleCalculation = (transactions) => {
    const basePrice = transactions * industry.rate
    const rangeMin = basePrice.toFixed(2)
    const rangeMax = (basePrice * 1.2).toFixed(2)
    const minRange = `$${Math.floor((industry?.minPrice - (industry?.minPrice * 20) / 100))} - $${industry?.minPrice}`
    setCalculation({ rangeMin, rangeMax, minRange })
    const priceRange = `$${rangeMin} - $${rangeMax}`
    return priceRange
  }

  return (
    <Fragment>
      <Box as="section">
        <Box
          sx={{
            zIndex: "0",
            position: "relative",
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundColor: "#000000ad",
            backgroundImage: `url("${Images?.image_5}")`,
            clipPath: {
              xs: "polygon(0% 0%, 100% 0%, 100% 80%, 50% 100%, 0 80%)",
              sm: "polygon(0% 0%, 100% 0%, 100% 85%, 50% 100%, 0 85%)",
              md: "polygon(0% 0%, 100% 0%, 100% 75%, 50% 100%, 0 75%)",
            },
            ":after": {
              top: "0",
              left: "0",
              right: "0",
              bottom: "0",
              content: "''",
              position: "absolute",
              backgroundColor: "#00000080"
            }
          }}>
          <Box
            sx={{
              zIndex: 10,
              gap: "10px",
              display: "grid",
              position: "relative",
              placeContent: "center",
              minHeight: { xs: "250px", md: "350px" },
            }}
          >
            <Typography
              sx={{
                color: "white",
                fontSize: "32px",
                fontWeight: "700",
                fontFamily: "Poppins",
              }}
              component="h2"
            >Pricing plans
            </Typography>
          </Box>
        </Box>
      </Box>

      {/* Services */}
      <Box as="section" sx={{ py: 3 }}>
        <Container maxWidth="xl">
          <Box sx={{ mb: 3, maxWidth: "600px", mx: "auto" }}>
            <Typography
              component="h5"
              mb="16px !important"
              className="sec-title"
            >Our Comprehensive Accounting Services
            </Typography>
            <Paragraph sx={{ fontSize: "14px", maxWidth: "400px", mx: "auto", textAlign: "center" }}>Our outsourcing services cover a wide range of accounting functions, tailored to meet your unique needs</Paragraph>
          </Box>

          <Grid container spacing={2}>
            {services?.map((service, i) => (
              <Grid key={i} size={{ xs: 12, md: 4 }}>
                <Box
                  sx={{
                    p: 1,
                    gap: "6px",
                    display: "flex",
                    borderRadius: "8px",
                    flexDirection: "column",
                    boxShadow: "0 0 20px 0 #00000017"
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between"
                    }}
                  >
                    <Box
                      sx={{
                        width: "40px",
                        height: "40px",
                        display: "grid",
                        bgcolor: "#FDE7E4",
                        borderRadius: "8px",
                        placeContent: "center"
                      }}
                    >
                      <Image
                        sx={{
                          height: "25px"
                        }}
                        alt="icon"
                        src={Images[`icon_${i + 1}`]}
                      />
                    </Box>

                    <Button
                      sx={{
                        color: "black",
                        fontWeight: "500",
                        bgcolor: "#FDE7E4",
                        borderRadius: "8px",
                        fontFamily: "Poppins",
                        textTransform: "capitalize",
                        boxShadow: "none !important",
                      }}
                      variant="contained"
                      component={i === 0 ? "a" : Button}
                      href={i === 0 ? "#calculater" : ""}
                      onClick={() => {
                        if (i !== 0) {
                          dispatch(showDetailDialogPricing())
                        }
                      }}
                    >{i === 0 ? "Check pricing" : "Custom plan"}
                    </Button>
                  </Box>
                  <Paragraph sx={{ fontWeight: "500" }}>{service?.title}</Paragraph>
                  <Paragraph sx={{ fontSize: "14px" }}>{service?.content}</Paragraph>
                </Box>
              </Grid>
            ))
            }
          </Grid>
        </Container>
      </Box>

      {/* Calculater */}
      <Box
        sx={{
          py: 6,
          zIndex: "20",
          bgcolor: "#fde6e3",
          position: "relative",
        }}
        as="section"
        id="calculater"
      >
        <Container maxWidth="xl">
          <Box
            sx={{
              p: 3,
              mx: "auto",
              maxWidth: "800px",
              bgcolor: "white",
              borderRadius: "20px",
              boxShadow: "0 5px 50px 10px #0000000f",
            }}
          >
            <Typography
              component="h5"
              className="sec-title"
            >Calculator: Monthly Accounting Price</Typography>
            <Box>
              <Grid container columnSpacing={3} rowSpacing={{ xs: 2, md: 0 }}>
                <Grid size={{ xs: 12, md: 8 }}>
                  <Box>
                    <Grid container spacing={{ xs: 2, md: 3 }}>
                      {industries?.map((indus, i) => (
                        <Grid key={i} size={{ xs: 12, sm: 6, lg: 4 }}>
                          <Box
                            sx={{
                              px: 2,
                              py: 1,
                              height: "100%",
                              display: "grid",
                              lineHeight: "1",
                              fontSize: "16px",
                              fontWeight: "400",
                              cursor: "pointer",
                              borderRadius: "6px",
                              border: "1px solid",
                              position: "relative",
                              fontFamily: "Poppins",
                              alignContent: "center",
                              textTransform: "capitalize",
                              color: indus?.name === industry?.name && "white",
                              bgcolor: indus?.name === industry?.name && "#d6422e",
                              borderColor: indus?.name !== industry?.name ? "#C9C9C9" : "transparent"
                            }}
                            onClick={() => setIndustry(indus)}
                            value={indus?.value}
                          >{indus?.name}
                            {indus?.name === industry?.name &&
                              <MuiIcons.FillCheck
                                sx={{
                                  top: "4px",
                                  right: "4px",
                                  position: "absolute",
                                  fontSize: "16px",
                                }}
                              />
                            }
                          </Box>
                        </Grid>
                      ))
                      }
                    </Grid>
                  </Box>
                </Grid>
                <Grid size={{ xs: 12, md: 4 }}>
                  <Box
                    sx={{
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      gap: { xs: 2, md: "8px" },
                      justifyContent: "space-between",
                    }}
                    as="form"
                    onSubmit={handleTransactionSubmit}
                  >
                    <Paragraph
                      sx={{
                        fontSize: "17px",
                        fontWeight: "500",
                        lineHeight: "1.1",
                        textAlign: "center",
                      }}
                    >Number of Transactions  (monthly)
                    </Paragraph>
                    <Input
                      sx={{
                        p: "0 6px",
                        display: "block",
                        borderRadius: "8px",
                        border: "1px solid #C9C9C9",
                        ":after": { display: "none" },
                        ":before": { display: "none" },

                        "input": {
                          fontSize: "15px",
                          fontFamily: "Poppins",
                          "&::placeholder": {
                            opacity: 1,
                            fontSize: "14px",
                            color: "#666666",
                            fontWeight: "400",
                            fontFamily: "Jost",
                            textAlign: "center",
                          },
                        }
                      }}
                      name="transactions"
                      placeholder="Enter the number of transaction"
                    />
                    <Button
                      sx={{
                        py: 0,
                        width: "100%",
                        fontSize: "16px",
                        fontWeight: "400",
                        boxShadow: "none",
                        fontFamily: "Jost",
                        bgcolor: "#d6422e",
                        borderRadius: "50px",
                        textTransform: "capitalize"
                      }}
                      type="submit"
                      variant="contained"
                    >Calculate
                    </Button>
                  </Box>
                </Grid>

                <Collapse
                  sx={{ width: "100%" }}
                  in={mailData?.status === 200 && varified === "true"}
                >
                  <Grid size={{ xs: 12 }}>
                    <Box
                      sx={{
                        p: 2,
                        mt: 1,
                        bgcolor: "#fde6e3",
                        borderRadius: "6px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "20px",
                          textAlign: "center",
                          fontFamily: "Poppins",
                          textTransform: "capitalize"
                        }}
                      >Industry : {industry?.name}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "20px",
                          fontWeight: "600",
                          textAlign: "center",
                          fontFamily: "Poppins",
                          textTransform: "capitalize"
                        }}
                        className="text-emerald-500"
                      >Price range : ${calculation?.rangeMin} - ${calculation?.rangeMax}
                      </Typography>
                      {industry?.minPrice > calculation?.rangeMin &&
                        <Typography
                          variant="body2"
                          sx={{
                            mt: 1.5,
                            fontFamily: "Poppins",
                            textTransform: "capitalize"
                          }}
                        >NOTE: This is our minimum price based on the transaction volume you have entered : {calculation?.minRange}
                        </Typography>
                      }
                    </Box>
                  </Grid>
                </Collapse>
              </Grid>
            </Box>
          </Box>
        </Container>
      </Box>
    </Fragment>
  )
}

export default Pricing