import Images from "../../assets/images";

export const services = [
  {
    title: "Expert Accounting",
    image: Images?.service_1,
    keyPoints: [
      {
        key: "Financial Reporting:",
        content: "Preparation of accurate financial statements, balance sheets, income statements, and cash flow reports.",
      },
      {
        key: "Tax Planning and Compliance:",
        content: "Ensuring adherence to local, national, and international tax regulations, and strategizing for tax efficiency.",
      },
      {
        key: "Auditing:",
        content: "Conducting internal and external audits to ensure accuracy and regulatory compliance in financial records.",
      },
      {
        key: "Budgeting and Forecasting:",
        content: "Developing budgets, financial models, and forecasting future financial performance.",
      },
    ]
  },
  {
    title: "Technical Controller",
    image: Images?.service_2,
    keyPoints: [
      {
        key: "Financial Oversight:",
        content: "Overseeing the preparation of financial statements, reports, and records to ensure accuracy and compliance with accounting standards.",
      },
      {
        key: "Regulatory Compliance:",
        content: "Ensuring all financial practices comply with relevant regulations, such as GAAP, IFRS, and local tax laws.",
      },
      {
        key: "Cash Flow Management:",
        content: "Monitoring and optimizing cash flow, ensuring the organization has sufficient liquidity for operations and growth.",
      },
      {
        key: "Cost Control:",
        content: "Implementing and maintaining cost control systems to ensure efficiency and reduce unnecessary expenditures.",
      },
    ]
  },
  {
    title: "Master CFO",
    image: Images?.service_3,
    keyPoints: [
      {
        key: "Strategic Financial Leadership:",
        content: "Leading the development and execution of financial strategies to support long-term business growth and profitability.",
      },
      {
        key: "Financial Planning and Analysis (FP&A):",
        content: "Overseeing budgeting, forecasting, and financial modeling to provide data-driven insights for decision-making.",
      },
      {
        key: "Capital Allocation:",
        content: "Ensuring optimal use of financial resources, managing capital structure, and overseeing investment strategies to maximize shareholder value.",
      },
      {
        key: "Risk Management:",
        content: "Identifying, assessing, and mitigating financial risks, including market risks, operational risks, and liquidity risks.",
      },
    ]
  },
  {
    title: "Sure Compliances",
    image: Images?.service_4,
    keyPoints: [
      {
        key: "Regulatory Adherence:",
        content: "Ensuring compliance with all applicable laws, regulations, and standards, including industry-specific requirements and local, national, and international regulations.",
      },
      {
        key: "Internal Controls:",
        content: "Developing and implementing internal control systems to prevent errors, fraud, and ensure accurate financial reporting and operational efficiency.",
      },
      {
        key: "Audit Management:",
        content: "Overseeing and managing internal and external audits to ensure compliance with financial and operational standards and addressing any audit findings or recommendations.",
      },
      {
        key: "Policy Development and Training:",
        content: "Creating, updating, and enforcing company policies and procedures related to compliance, and providing training to employees to ensure understanding and adherence to these policies.",
      },
    ]
  },
  {
    title: "Personnel Payroll",
    image: Images?.service_5,
    keyPoints: [
      {
        key: "Data Collection and Integration:",
        content: "Gathering and integrating data from various digital sources, such as CRM systems, web analytics, and financial databases, to create comprehensive reports.",
      },
      {
        key: "Report Generation:",
        content: "Using digital tools and software to generate reports, including dashboards, visualizations, and performance metrics that provide insights into key business indicators.",
      },
      {
        key: "Data Accuracy and Quality:",
        content: "Ensuring the accuracy and quality of the data used in digital reports, including validation, cleansing, and verification processes.",
      },
      {
        key: "Automated Reporting Systems:",
        content: "Implementing and managing automated reporting systems to streamline the reporting process, improve efficiency, and reduce manual errors.",
      },
    ]
  },
  {
    title: "Digital Reports",
    image: Images?.service_6,
    keyPoints: [
      {
        key: "Payroll Processing:",
        content: "Managing the accurate and timely processing of employee payroll, including calculations for wages, salaries, bonuses, and deductions.",
      },
      {
        key: "Tax Compliance:",
        content: "Ensuring compliance with federal, state, and local tax regulations, including accurate withholding, reporting, and payment of employee taxes.",
      },
      {
        key: "Benefits Administration:",
        content: "Overseeing the administration of employee benefits such as health insurance, retirement plans, and other perks, and ensuring correct deductions and contributions.",
      },
      {
        key: "Record Keeping:",
        content: "Maintaining accurate and confidential payroll records, including timekeeping, employee earnings, and tax documents, and ensuring they are securely stored and easily accessible.",
      },
    ]
  },
]