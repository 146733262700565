import { Collapse, Container, IconButton, Link, Stack, styled } from "@mui/material"
import { Link as RLink, useLocation } from "react-router-dom"
import { useState } from "react"
import MuiIcons from "../../assets/js/MuiIcon"

const Div = styled("div")(() => ({}))
const Span = styled("span")(() => ({}))

const menus = [
  { name: "home", path: "/" },
  { name: "our services", path: "/services" },
  { name: "pricing plan", path: "/pricing" },
]

function Header() {
  const [active, setActive] = useState(false)
  const { pathname } = useLocation()

  return (
    <Div as="header">
      <Container maxWidth="xxl">
        <Div
          sx={{
            py: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Div className="brand-logo">
            <Link
              to="/"
              sx={{
                color: "black",
                fontSize: "30px",
                fontWeight: "500",
                textWrap: "nowrap",
                fontFamily: "Koulen",
                textDecoration: "none",
                textTransform: "uppercase",
              }}
              component={RLink}
            >
              <Span>ExpertS </Span>
              <Span className="text-punch-600">Finacc</Span>
            </Link>
          </Div>

          <Stack
            spacing={4}
            direction="row"
            component="nav"
            sx={{
              display: { xs: "none", md: "flex" }
            }}
          >
            {menus?.map((menu, i) => (
              <Link
                key={i}
                to={menu?.path}
                component={RLink}
                className="nav-link"
                sx={{
                  mx: 1,
                  textWrap: "nowrap",
                  position: "relative",
                  textDecoration: "none",
                  textTransform: "capitalize",
                  transitionDuration: "350ms",
                  color: pathname === menu?.path ? "#d6422e" : "black",

                  ":after": {
                    left: "0",
                    bottom: "0",
                    height: "2px",
                    content: "''",
                    bgcolor: "#d6422e",
                    position: "absolute",
                    transitionDuration: "350ms",
                    width: pathname === menu?.path ? "100%" : "0%",
                  },

                  ":hover": { ":after": { width: "100%" } }
                }}
              >{menu?.name}
              </Link>
            ))
            }
          </Stack>

          <Div>
            <Link
              color="#d6422e"
              component={RLink}
              to="mailto:info@expertsfinacc.com"
              sx={{ display: { xs: "none", md: "inline-block" } }}
            >info@expertsfinacc.com
            </Link>

            <IconButton
              sx={{
                color: "#d6422e",
                display: { xs: "inline-flex", md: "none" }
              }}
              onClick={() => { setActive(!active) }}
            >
              {active ?
                <MuiIcons.Close /> :
                <MuiIcons.Menu />
              }
            </IconButton>
          </Div>
        </Div>

        <Div sx={{ display: { xs: "block", md: "none" } }}>
          <Collapse in={active}>
            <Div
              sx={{ display: "flex", flexDirection: "column", gap: "8px" }}
            >
              {menus?.map((menu, i) => (
                <Div key={i} sx={{ textAlign: "center" }}>
                  <Link
                    to={menu?.path}
                    component={RLink}
                    className="nav-link"
                    sx={{
                      mx: 1,
                      textWrap: "nowrap",
                      position: "relative",
                      textDecoration: "none",
                      textTransform: "capitalize",
                      transitionDuration: "350ms",
                      color: pathname === menu?.path ? "#d6422e" : "black",

                      ":after": {
                        left: "0",
                        bottom: "0",
                        height: "2px",
                        content: "''",
                        bgcolor: "#d6422e",
                        position: "absolute",
                        transitionDuration: "350ms",
                        width: pathname === menu?.path ? "100%" : "0%",
                      },

                      ":hover": { ":after": { width: "100%" } }
                    }}
                  >{menu?.name}
                  </Link>
                </Div>
              ))
              }
              <Link
                color="#d6422e"
                component={RLink}
                to="mailto:info@expertsfinacc.com"
                sx={{ display: "block", textAlign: "center", mb: 1 }}
              >info@expertsfinacc.com
              </Link>
            </Div>
          </Collapse>
        </Div>
      </Container>
    </Div>
  )
}

export default Header