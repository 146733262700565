import icon_1 from "./icons/icon-1.png"
import icon_2 from "./icons/icon-2.png"
import icon_3 from "./icons/icon-3.png"
import icon_4 from "./icons/icon-4.png"
import icon_5 from "./icons/icon-5.png"
import icon_6 from "./icons/icon-6.png"
import service_1 from "./service-1.png"
import service_2 from "./service-2.png"
import service_3 from "./service-3.png"
import service_4 from "./service-4.png"
import service_5 from "./service-5.png"
import service_6 from "./service-6.png"
import banner_1 from "./banner-1.png"
import image_1 from "./image-1.png"
import image_2 from "./image-2.png"
import image_3 from "./image-3.png"
import image_4 from "./image-4.png"
import image_5 from "./image-5.png"
import tool_1 from "./tool-1.png"
import tool_2 from "./tool-2.png"
import tool_3 from "./tool-3.png"
import tool_4 from "./tool-4.png"
import tool_5 from "./tool-5.png"
import tool_6 from "./tool-6.png"
import tool_7 from "./tool-7.png"
import svg_1 from "./svg-1.svg"
import svg_2 from "./svg-2.svg"

const Images = {
  service_1,
  service_2,
  service_3,
  service_4,
  service_5,
  service_6,
  banner_1,
  image_1,
  image_2,
  image_3,
  image_4,
  image_5,
  tool_1,
  tool_2,
  tool_3,
  tool_4,
  tool_5,
  tool_6,
  tool_7,
  icon_1,
  icon_2,
  icon_3,
  icon_4,
  icon_5,
  icon_6,
  svg_1,
  svg_2,
}

export default Images